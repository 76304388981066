import React from 'react';
import Layout from '../templates/layout';
import BasePageContent from '../components/utils/BasePageContent';

const Services = () => (
  <Layout>
    <BasePageContent  />
  </Layout>
);

export const Head = () => <title>Opis usługi | Zapłatomat</title>;

export default Services;
